import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Box from 'components/box';
import { graphql } from 'gatsby';
import IOHeadline from 'components/io-headline';
import { AnimatedContainer } from 'components/header';
import { Intro, IntroText } from 'components/intro/intro.css';

const Project = ({ data }) => (
  <Layout>
    <AnimatedContainer>
      <IOHeadline>
        <Intro>
          <IntroText as="h1">What i did and what i’m looking for</IntroText>
        </Intro>
      </IOHeadline>
      <Box>{data.whatJson.intro.childMarkdownRemark.rawMarkdownBody}</Box>
      <Box>
        <div
          dangerouslySetInnerHTML={{
            __html: data.whatJson.content.childMarkdownRemark.html,
          }}
        />
      </Box>
    </AnimatedContainer>
  </Layout>
);

Project.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Project;

export const query = graphql`
  query WhatQuery {
    whatJson {
      title
      intro {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      cols {
        title
        copy
      }
    }
  }
`;
